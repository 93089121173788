import React from "react";
import Intro from "./Intro";
import Logo from "./Logo";
import '../../styles/Home.css';

function Home(props){
    return(
        <div className="home" id="Home">
            <Intro />
            {/* <Logo /> */}
        </div>
    )
}

export default Home;