import React from "react";

function Ending(){
    return(
        <div>
            <p>I am always open for new opportunities.</p>
            <p>Feel free to contact me 24/7!</p>
        </div>
    )
}

export default Ending;