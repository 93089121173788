import React from "react";

function Resume(){
    return(
        <div className="about-resume">
            <a href="https://drive.google.com/file/d/1v5rR5f9Sl-sdbz_qv9M78c_VnO-UEc08/view?usp=sharing" rel="noreferrer" target="_blank">Resume</a>
        </div>
    )
    
}

export default Resume;