import React from "react";


function Intro(){
    return(
        <div className="about-intro">
            <p>
                Hi there! My name is <span>Zhenjie</span> and I am currently a junior in CalPoly SLO. </p>
            <p>I am a hard working individual that gets the job done with quality code. </p>
            <p>Got any project ideas? Let me know!</p>
        </div>
    )
}


export default Intro;