import React from "react";
import {motion} from 'framer-motion';

function experienceItems(item){
    return <div className="experience-item" key={item}>
        <p>{item}</p>
    </div>
}

function Experience(props){
    const programming = ["Python", "C", "C#", "Java", "HTML", "CSS", "OOP", "MongoDB", "Javascript", "React", "Node"]
    const skills = ["Friendly", "Open-minded", "Adaptive", "Team Player", "Detail-oriented", "Self-sufficient"]
    const experience = [
        "8/23-present: Tech Team Member of The SLOHacks Club",
        "3/23-present: Software Team Member of Robotics Club",
        "3/24-present: SWE Intern at Spread Goodness"
    ]
    return(
        <motion.div 
        className= "about-box"
        initial= {props.initial}
        animate= {props.animate}
        variants= {props.variants}
        custom= {props.custom}>
            <h1>{props.name}</h1>
            <div className="experience-items">
                {
                    props.name === "Programming" ? programming.map(experienceItems) :
                    props.name === "Skills & Traits" ? skills.map(experienceItems) :
                    experience.map(experienceItems)
                }
            </div>
        </motion.div>
    )
    
}

export default Experience;

