import React from "react";

function Greet(){
    return(
        <div className="greet">
            <p>- Nice to meet you!</p>
            <h1>Zhenjie Liang</h1>
            <p>Student, Full stack, SWE</p>
        </div>
    )
}

export default Greet;