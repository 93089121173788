const proj = [
    {
        name : "Ecosytem Simulator",
        tech : ["Unity, ", "C#"],
        description : "A simulation game that allows users to create their own ecosystem and observe the interactions between different species.",
        completed: false,
        source : "https://www.youtube.com/watch?v=uHgt8giw1LY"
    },
    {
        name : "PolyTask",
        tech : ["MongoDB, ", "Express, ", "React, ", "Node.js, ", "JWT"],
        description : "A full stack web application that allows users to create and manage tasks.",
        completed: true,
        source : "https://github.com/BigZhenjie/PolyTask/tree/main",
    },
    {
        name: "PhotoAppApi",
        tech: ["Java, ", "Spring Boot, ", "JJWT", " and more"],
        description: "A microservice backend that utilized Spring and multiple libraries such as Spring Security, Spring Data, and JJWT. This is purely for practie purposes and is not a real application.",
        completed: true,
        source: "https://github.com/BigZhenjie/springboot-project"
    },
    {
        name: "Expense Tracker",
        tech: ["MongoDB, ", "Express, ", "React, ", "Node.js"],
        description: "A personal expense tracker that allows users to track their expenses and income. It also provides a visual representation of the user's expenses and income through a pie chart.",
        completed: true,
        source: "https://github.com/BigZhenjie/expense-tracker"
    },
    {
        name: "Weather Alert",
        tech: ["Python, ", "Twillio API"],
        description: "A script written in python that alerts the user the weather of their location on a daily basis through messages.",
        completed: true,
        source: "https://github.com/BigZhenjie/weather-alert"
    },
    {
        name: "Birthday Billboard",
        tech: ["Python, ", "Spotify API, ", "Beautiful Soup"],
        description: "This is a web scrapping script that finds all songs that were on the billboard on your birthday and make the avaliable songs on spotify into a playlist in a desired account.",
        completed: true,
        source: "https://github.com/BigZhenjie/birthday-billboard"
    },
    {
        name: "Amazon Price Tracker",
        tech: ["Python, ", "Beautiful Soup "],
        description: "Simple price tracker script that alerts user when a specific item on Amazon is dropped below a target price.",
        completed: true,
        source: "https://github.com/BigZhenjie/amazon-price-tracker"
    },
    {
        name: "Directory Tree",
        tech: ["C"],
        description: "This C program recursively traverses directories and prints the tree structure by indenting child directories. It uses POSIX functions like opendir() and formats the output for a clean tree view.",
        completed: true,
        source: "https://github.com/BigZhenjie/directory-tree"
    },
    {
        name: "File System CLI Clone",
        tech: ["C"],
        description: "This C program emulates a file system using inodes. It allows file manipulation such as touch and directory manipulation such as mkdir.",
        completed: true,
        source: "https://github.com/BigZhenjie/File-Simulator"
    },
    

]

export default proj;