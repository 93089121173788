import React from "react";
import ContactForm from "./ContactForm";
import ContactInfo from "./ContactInfo";
import Heading from "./Heading";

import "../../styles/Contact.css"

function Contact(){
    return (
        <div className="contact" id="Contact">
            <Heading />
            <div className="contact-main">
                <ContactInfo />
                <ContactForm />
            </div>
        </div>
    )
}

export default Contact;