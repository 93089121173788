import React from "react";

function ContactItem(props){
    return(
        <div className={props.contactType}>
            <p>{props.contactType}</p>
            <p>{props.contact}</p>
        </div>
    )
}

export default ContactItem;