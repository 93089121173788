

//i need to set a timeout because i have no idea how to wait for the react to finish loading the html file
//i tried using document.DOMready but that didnt work
//also tried using useEffect but that didnt work as well

//lol I didnt know I have to assign it to a variable
//now it works with useEffect.
const effect = () => {
    const dynamicText = document.querySelector(".typing h1 span");



    const words = ["Coder", "Team Player", "Game Changer"]

    var wordIndex = 0;
    var charIndex = 0;
    let isDelete = false;

    const typeEffect = () =>{
        const curWord = words[wordIndex];
        const curChar = curWord.substring(0, charIndex);
        dynamicText.textContent = curChar;
        dynamicText.classList.add("stop-blinking");

        if(!isDelete && charIndex < curWord.length){
            //we are not deleting yet because we havent typed out a word
            charIndex++;
            setTimeout(typeEffect, 150);
        }
        else if(isDelete && charIndex > 0){
            //now we are deleting
            charIndex--;
            setTimeout(typeEffect, 70);
        }
        else{
            //else we set isDelete to true because we typed out a word
            //and change the word
            isDelete = !isDelete;
            dynamicText.classList.remove("stop-blinking");
            wordIndex = !isDelete ? (wordIndex + 1) % words.length : wordIndex;
            setTimeout(typeEffect, 3000);
        }

    }

    typeEffect();

}


export default effect;