import React from "react";
import Heading from "./Heading";
import Projects from "./Projects";
import "../../styles/Portfolio.css"


function Portfolio(){
    return(
        <div className="portfolio" id="Portfolio">
            <Heading />
            <Projects />
        </div>
    )
}

export default Portfolio;