import React, {useRef} from "react";
import Ending from "./Ending";
import {motion, useInView} from 'framer-motion';

function ContactInfo(){
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});
    return(
        <motion.div 
        className="contact-info" 
        initial={{
            opacity: 0,
            x: -200
        }}
        animate={{
            opacity: isInView ? 1 : 0,
            x: isInView ? 0 : -200,
            transition: {
                duration: 0.7,
                delay: 0.2
            }
        }}
        ref={ref}>
            <Ending />
            <p>(415)734-0673</p>
            <p>1741zhenjie@gmail.com</p>
        </motion.div>
    )
}

export default ContactInfo;