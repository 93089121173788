import React, {useRef} from "react";
import {motion, useInView} from 'framer-motion';

function Heading(){
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});
    return(
        <motion.div 
        className="portfolio-heading"
        style={{
            transform: isInView ? "none" : "translateX(-200px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
          }}
        ref={ref}>
            <p>- Projects</p>
            <p>My Projects</p>
        </motion.div>
    )
}

export default Heading;