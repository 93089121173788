import React from "react";

function Profile(){
    return(
        <div className="profile">
            {/* <img className = "profile-pic" src="../../../public\logo512.png" alt="Profile" /> */}
            <h2 className="profile-name">Zhenjie Liang<span>Zhenjie Liang</span></h2>
        </div>
    )
}

export default Profile;

