import React from "react";
import {motion} from 'framer-motion';

function Project(props){
    return(
        <motion.div 
        className="project"
        initial= {props.initial}
        animate= {props.animate}
        variants= {props.variants}
        custom= {props.custom}>
            <p>{props.projectName}</p>
            <p className="tech">{props.tech}</p>
            <p>{props.description}</p>
            <a href={props.link} target="_blank" rel="noopener noreferrer">
                {props.completed ? 'Source →' : 'Work in progress...'}
            </a>
        </motion.div>
    )
}

export default Project;
