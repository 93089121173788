import React, { useState, useRef } from "react";
import { motion, useInView } from 'framer-motion';
import sendEmail from "../../pages/email.js";


function ContactForm() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const variants = {
    initial: {
      opacity: 0,
      x: 100
    },
    animate :{
      opacity: isInView ? 1 : 0,
      x: isInView ? 0 : 100,
      transition: {
        staggerChildren: 0.2,
        duration: 0.5,
        ease: "backInOut"
      }
    }
  }

  const [formData, setFormData] = useState({
    email: '',
    name: '',
    message: ''
  });

  function handleChange(e) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }


  async function handleSubmit(e) {
    e.preventDefault();

    // access form data
    const { email, name, message } = formData;

    // send email
    await sendEmail(email, name, message);
    alert("Sent!");
  }

  return (
    <motion.form action="/contact-send" method="POST" onSubmit={handleSubmit}
    variants={variants} animate="animate" initial="initial" ref={ref}>
      <motion.input
        type="Your Email" name="email" value={formData.email} onChange={handleChange} placeholder=" Your Email" variants={variants} required />
      <motion.input
        type="Your Name" name="name" value={formData.name} onChange={handleChange} placeholder="Your Name" variants={variants} required />
      <motion.textarea name="message" id="message" placeholder="Type your message here..." value={formData.message}  onChange={handleChange} variants={variants} required />
      <motion.button type="submit" variants={variants} >Submit</motion.button>
    </motion.form>
  )
}

export default ContactForm;