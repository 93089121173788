import React from "react";
import Profile from "./Profile";
import Navigator from "./Navigator";
import MyFooter from "./MyFooter";
import '../../styles/Sidebar.css';

function Sidebar(){
    return(
        <div className="sidebar">
            <Profile />
            <Navigator />
            <MyFooter />
        </div>
    )
}


export default Sidebar;