import React from "react";


function MyFooter(){
    return(
        <div className="my-footer">
            <p>
                Copyright © 2023 Zhenjie Liang.
            </p>
            <p>All rights reserved.</p>
        </div>
    )
}

export default MyFooter;