import React, {useEffect, useRef} from "react";
import effect from '../../behavior/typeEffect.js';
import {useInView, motion} from 'framer-motion';

function Intro(){
    useEffect(() =>{
        effect();
    }, [])
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});
    return(

        // <div className="intro">
        //     <h1>Hi, I am <span>Zhenjie!</span></h1>
        //     <div className="typing">
        //        <h1>A <span></span></h1> 
        //     </div>
        //     <h1>based in San Francisco.</h1>
        //     <br />
        //     <p>Currently a student in CalPoly SLO.</p>
        // </div>
        <motion.div 
        className="intro"
        style={{
            transform: isInView ? "none" : "translateX(-200px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
          }}
        ref={ref}>
            <h1>Hi, I am <span>Zhenjie!</span></h1>
            <div className="typing">
               <h1>A <span></span></h1> 
            </div>
            <h1>based in San Francisco.</h1>
            <br />
            <p>Currently a student in CalPoly SLO.</p>
        </motion.div>
        
    )
}

export default Intro;