import React from "react";
import ContactItem from "./ContactItem";
import Resume from "./resume";

function Contact(){
    return(
        <div 
        className="about-contact">
            <ContactItem
            contactType="Phone"
            contact="(415)734-0673" />
            
            <ContactItem 
            contactType="Email"
            contact="1741zhenjie@gmail.com" />

            <Resume />
        </div>
    )
}

export default Contact;