import '../styles/App.css';
import '../styles/Content.css';
import Sidebar from './sidebar/Sidebar';
import Home from "./home/Home";
import About from "./about/About";
import Portfolio from './portfolio/Portfolio';
import Contact from './Contact/Contact';

function App() {
  return (
    <div className='app'>
      <Sidebar />
      <div className="content">
      <Home />
      <About />
      <Portfolio />
      <Contact />
      </div>
    </div>
  );
}

export default App;
