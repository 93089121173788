
import emailjs from '@emailjs/nodejs';
import validator from "validator";


const sendEmail = async (email, name, message) =>{
    console.log(process.env.REACT_APP_SERVICE_ID);
    if(validator.isEmail(email)){
        const params = {
            email: email,
            name: name,
            message: message
        }

        emailjs
        .send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, params, {
            publicKey: process.env.REACT_APP_PUBLIC_KEY,
            privateKey: process.env.REACT_APP_PRIVATE_KEY, // optional, highly recommended for security reasons
        })
        .then(
            (response) => {
                return "Success! I will get back to you within 1 business day."
            },
            (err) => {
                return "Sorry! Something went wrong."
            },
        );
    }
    else{
        return "Email is invalid!";
    }
}



export default sendEmail;