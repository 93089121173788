import React, {useRef} from "react";
import {motion, useInView} from 'framer-motion';

function Heading(){
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});
    return(
        <motion.div 
        className="contact-heading"
        initial={{
            opacity: 0,
            x: 200
        }}
        animate={{
            opacity: isInView ? 1 : 0,
            x: isInView ? 0 : 200,
            transition: {
                duration: 0.7,
                delay: 0.2
            }
        }}
        ref={ref}
        >
            <p>- Contact</p>
            <p>Let's Connect!</p>
        </motion.div>
    )
}

export default Heading;