import React from "react";
import Navitem from "./Navitem";


const items = ["Home", "About", "Portfolio", "Contact"]

function createItems(item){
    return(
        <Navitem 
            itemName={item}
            onClick={goTo}
            key={item}
        />
    )
}

function goTo(e){
    // console.log(e.target.innerText);
    const target = e.target.innerText;
    const element = document.getElementById(target);
    if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' });
    }
}

function Navigator(){
    return(
        <ul className="navigator">
            {items.map(createItems)}
        </ul>
    )
    
}

export default Navigator;