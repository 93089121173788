import React, {useRef} from "react";
import Project from "./Project";
import proj from "./projectSource";
import {motion, useInView} from 'framer-motion';




function Projects(){
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});

    const variants = {
        initial:{
            opacity: 0,
            y: 200
        },
        animate: (i) => ({
            opacity: isInView ? 1 : 0,
            y: isInView ? 0 : 200,
            transition: {
                duration: 0.7,
                delay: 0.2 * i
            }
        })
    }

    function projectItem(item, ind){
        return(
            <Project
            key={ind}
            projectName={item.name}
            tech={item.tech}
            description={item.description}
            completed={item.completed}
            link={item.source}
            initial= "initial"
            animate= "animate"
            variants={variants}
            custom={ind}
            />
        )
    }

    return(
        <motion.div 
        className="projects"
        initial="initial"
        animate="animate"
        variants={variants}
        ref={ref}>
            {proj.map(projectItem)}
        </motion.div>
        
    )
}

export default Projects;