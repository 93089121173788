import React from "react";

function Navitem(props){
    return(
        <li className="nav-item" onClick={props.onClick}>
            {props.itemName}
        </li>
    )
}

export default Navitem;