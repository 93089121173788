import React, {useRef} from "react";
import Intro from "./Intro";
import Greet from "./Greet";
import Experience from "./Experience";
import Contact from "./Contact";
import "../../styles/About.css"
import {motion, useInView} from 'framer-motion';

function About(){
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});

    const variants = {
        initial:{
            opacity: 0,
            y: 200
        },
        animate: (i) => ({
            opacity: isInView ? 1 : 0,
            y: isInView ? 0 : 200,
            transition: {
                duration: 0.7,
                delay: 0.2 * i
            }
        })
    }

    const exp = ["Programming", "Skills & Traits", "Experience"]
    return(
        <div className="about" id="About">
            <motion.div 
            className="about-top"
            style={{
                transform: isInView ? "none" : "translateY(-200px)",
                opacity: isInView ? 1 : 0,
                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
              }}
            ref={ref}>
                <Greet />
                <div className="about-top-right">
                <Intro />
                <Contact />
                </div>
            </motion.div>
            <motion.div 
            className="about-bottom"
            initial="initial"
            animate="animate"
            variants={variants}
            ref={ref}>
                {exp.map( (item, ind) => {
                    return <Experience 
                            key={ind} 
                            name={item} 
                            initial= "initial"
                            animate= "animate"
                            variants={variants}
                            custom={ind}
                            />
                })}
            </motion.div>

        </div>
    )
}


export default About;